<template>
  <el-checkbox class="checkbox-img-item" :checked="true" :disabled="disabled" @change="toggleRowSelection(data)">
    <div class="img-wrapper mb10">
      <defaultImg v-if="displayImagePath" :src="displayImagePath"></defaultImg>
      <el-tooltip :content="title" effect="dark" placement="bottom">
        <div class="img-text text-cut">{{ title }}</div>
      </el-tooltip>
    </div>
  </el-checkbox>
</template>

<script>
import { get } from 'lodash'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    disabledValue: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    displayImagePath({ data }) {
      return get(data, 'thumbnailPath')
    },
    title({ data }) {
      return get(data, 'title') || get(data, 'name')
    },
    disabled({ disabledValue, data }) {
      return !!disabledValue.find((id) => id == data.id)
    }
  },

  methods: {
    toggleRowSelection(data) {
      this.$emit('toggleRowSelection', data)
    }
  }
}
</script>

<style lang="scss" scoped src="./scss/itemScss.scss"></style>
