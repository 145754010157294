<template>
  <el-dialog
    v-el-drag-dialog
    :title="option.title"
    :visible.sync="dialogVisible"
    top="5vh"
    width="888px"
    custom-class="distribution-dialog dialog-header-border"
    append-to-body
    @opened="getElTableInstance"
    @closed="initCheckAllData"
  >
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="tableOption"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <template #menuLeft>
        <el-checkbox
          v-model="checkedAll"
          :indeterminate="indeterminate"
          :disabled="checkedAllDisabled"
          class="checkbox-large"
          @change="checkedAllChange"
          >全选</el-checkbox
        >
      </template>

      <template #nameSearchForm>
        {{ info.name }}
      </template>

      <template #countSearchForm>
        {{ info.count }}
      </template>

      <template #memberLevelId="{ row }">
        {{ levelName(row) }}
      </template>

      <template #imgText="{ row }">
        <div class="flex-middle">
          <defaultImg :src="getDisplayImagePath(row)" style="width: 50px; height: 50px"></defaultImg>
          <div class="ml10">{{ row.name }}</div>
        </div>
      </template>

      <template #thumbnailPath="{ row }">
        <div class="flex-center-middle">
          <defaultImg :src="row.thumbnailPath" style="width: 50px; height: 50px"></defaultImg>
        </div>
      </template>
    </avue-crud>

    <el-divider></el-divider>

    <!-- <template v-if="isShowProtoForm">
       <ProtoForm ref="form" :form="form" :type="type" />
      <el-divider></el-divider>
    </template> -->

    <!-- 选择的列表 -->

    <div class="mt10">
      已选择{{ option.title }}
      <color-text-btn @click="clearSelection1" v-if="cancleTag.length > 0"
        >{{ cancleTag.title }} {{ cancleTag.length }}</color-text-btn
      >
    </div>

    <InfiniteScroll
      v-show="hasSelectionDataAll"
      class="checkbox-img"
      :span="4"
      :isShowLoading="false"
      v-loading="selectionDataAllLoading"
      :data="infiniteScrollData"
      :total="selectionDataAll.length"
      @load="load"
    >
      <template v-slot="{ data }">
        <component
          :disabledValue="disabledValue"
          :is="componentName"
          :data="data"
          @toggleRowSelection="toggleRowSelection"
        />
      </template>
    </InfiniteScroll>

    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" v-if="showSubmit" :loading="selectionDataAllLoading || submitLoading" @click="onsubmit"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import InfiniteScroll from '@/components/infiniteScroll'
//import ProtoForm from './module/protoForm'
import ProtoItem from './module/protoItem'
import PictureItem from './module/pictureItem'
import DistributorItem from './module/distributorItem'

import { avueCrud, dialogComponentsMixin, checkAllDataMixin } from '@/mixins'
import { setDefault } from '@/components/avue/utils/util'
import { get } from 'lodash'

export default {
  components: {
    InfiniteScroll,
    //ProtoForm,
    ProtoItem,
    PictureItem,
    DistributorItem
  },

  mixins: [
    dialogComponentsMixin,
    avueCrud(),
    // 放在avueCrud之后，否则checkAllDataMixin的afterInit会被覆盖
    checkAllDataMixin()
  ],

  props: {
    option: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => []
    },

    disabledValue: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'default'
    },

    info: {
      type: Object,
      default: () => ({})
    },

    isEdit: Boolean,
    form: Object,
    resetMergeData: Object,
    resetInfiniteScrollPage: {
      type: Object,
      default: () => ({
        pageIndex: 1,
        pageSize: 12
      })
    }
  },

  data() {
    return {
      submitLoading: false,
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      },
      infiniteScrollPage: this.resetInfiniteScrollPage
    }
  },

  computed: {
    cancleTag() {
      let length = this.selectionDataAll.length
      let title = '取消全部'
      return {
        length,
        title
      }
    },
    permisionList({ option }) {
      return option.permisionList || {}
    },

    showSubmit({ isEdit, permisionList }) {
      return isEdit ? permisionList.hasEdit : permisionList.hasAdd
    },

    getListFn({ option }) {
      return option.getListFn
    },

    submitFuncApi({ isEdit, option }) {
      const { addSubmitFuncApi, editSubmitFuncApi } = option
      return isEdit ? editSubmitFuncApi : addSubmitFuncApi
    },

    componentName({ option }) {
      return option.componentName
    },

    getDisplayImagePath() {
      return (row) => {
        return get(row, 'styleList.[0].styleDisplayImageList.[0].displayImagePath') || get(row, 'thumbnailPath')
      }
    },

    isShowProtoForm({ form }) {
      return !!form
    },

    infiniteScrollData({ selectionDataAll, infiniteScrollPage: { pageIndex, pageSize } }) {
      return selectionDataAll.slice(0, pageIndex * pageSize)
    },

    levelName() {
      return (row) => {
        return get(row, 'paymentAccountSimple.memberLevel.levelName') || '暂无'
      }
    }
  },

  watch: {
    option: {
      handler(n) {
        let { tableOption } = n
        tableOption && this.$store.dispatch('HandleOption', tableOption)
        this.tableOption = tableOption
      },
      deep: true,
      immediate: true
    },

    selectionDataAll: {
      handler(n) {
        let {
          infiniteScrollPage: { pageIndex, pageSize },
          infiniteScrollPage
        } = this
        let maxPage = Math.ceil(n.length / pageSize)
        // pageIndex最小为1，否则load不生效
        if (pageIndex > maxPage) infiniteScrollPage.pageIndex = Math.max(maxPage, 1)
      },
      deep: true
    },

    // 处理在全选并点击确定之后，全部数据未返回却打开弹窗时，选择数据为空的问题
    value: {
      handler(n) {
        if (this.dialogVisible) {
          this.setSelection(n)
        }
      },
      deep: true
    },

    checkedAll(n) {
      if (n) return
      setTimeout(() => {
        this.setSelection(this.disabledValue)
      }, 0)
    },

    dialogVisible: {
      handler(n) {
        if (!n) {
          this.dialogClose('close')
        }
      },
      immediate: true
    }
  },

  methods: {
    clearSelection1() {
      this.clearSelection()
      setTimeout(() => {
        this.setSelection(this.disabledValue)
      }, 0)
    },

    async checkForm() {
      const {
        isShowProtoForm,
        $refs: { form }
      } = this
      if (!isShowProtoForm || !form) return true
      return await form.validate()
    },

    async onsubmit() {
      //校验表单
      if (!(await this.checkForm())) return
      // 防止在全选之后，立即点击确定，导致this.selectionDataAll数据缺少
      let selectionDataAll = await this.getSelectionDataAllArr()
      if (!selectionDataAll.length) {
        return this.$message.warning('请先选择数据')
      }
      let value = selectionDataAll.map((row) => row.id)

      this.submitLoading = true
      const [err] = await this.doFunc(value)
      this.submitLoading = false
      if (err) return
      this.$message.success('操作成功')
      this.$emit('successHandler', value)
      this.$emit('input', value)
      this.dialogVisible = false
    },

    async doFunc(value) {
      try {
        const { code, detail } = await this.submitFuncApi.call(this, value)
        return [!$SUC({ code }), detail || []]
      } catch {
        return [true, []]
      }
    },

    handleTableData(data) {
      setDefault(data, ['styleList', 'sizeList'])
      return data.map((item) => {
        item.$styleName = item.styleList.map((style) => style.name).join('、')
        item.$sizeName = item.sizeList.map((style) => style.sizeName).join('、')
        return item
      })
    },

    load() {
      this.infiniteScrollPage.pageIndex++
    },

    async dialogOpen(value = this.value) {
      this.dialogReturnType = ''

      this.dialogVisible = true
      this.setSelection(value)

      this.searchForm = {}
      this.$nextTick(function () {
        this.$refs.crud?.formInit()
      })

      this.searchChange()
      return new Promise((resolve, reject) => {
        this.dialogResolveFn = resolve
        this.dialogRejectFn = reject
      })
    },

    dialogClose(value) {
      let fnType = value === 'close' ? 'dialogReject' : 'dialogResolve'
      if (this.dialogReturnType === 'success' && fnType === 'dialogReject') return
      return this[fnType](value)
    },

    dialogResolve(value) {
      return typeof this.dialogResolveFn === 'function' && this.dialogResolveFn(value)
    },

    dialogReject(value) {
      return typeof this.dialogRejectFn === 'function' && this.dialogRejectFn(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution-dialog {
  .formPart {
    margin: 0;
  }
  .checkbox-img {
    min-height: 100px;
    max-height: 300px;
  }
}
::v-deep {
  .checkbox-img {
    .checkbox-img-col {
      margin-bottom: 15px;
    }
  }
}
</style>
